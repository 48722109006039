<template>
  <div v-if="showDebug()">deb: {{ modelValue }} | {{ internalValue }}</div>
  <v-text-field
    class="align-right"
    min-width="100"
    max-width="120"
    ref="numberfield"
    v-model="beautifulValue"
    :id="id"
    :name="name"
    :class="class"
    :density="density"
    :filled="filled"
    :loading="loading"
    :disabled="disabled"
    :rules="rules"
    :readonly="readonly"
    :required="required"
    :autocomplete="autocomplete"
    :hide-details="hideDetails"
    :label="label"
    type="text"
    @keyup="keyup"
    @keydown="keydown"
    @change="change"
    @focus="focus"
  ></v-text-field>
</template>
<script lang="ts">
import { useSettingsStore } from '@/store/SettingsStore'
import { $rules } from '@/plugins/rules'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'vNumberField',
  props: {
    modelValue: { type: Number },
    precision: { type: Number, default: 2 },
    preventUpdateOnErrorState: { type: Boolean, default: true },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    id: { type: String, default: 'aNumberfield' },
    name: { type: String, default: 'aNumberfield' },
    label: { type: String, default: '' },
    class: { type: String, default: '' },
    density: { type: String, default: 'compact' },
    autocomplete: { type: String, default: 'off' },
    hideDetails: { type: Boolean, required: false, dafault: false },

    filled: { type: Boolean, default: false },
    rules: {
      type: Array,
      required: false,
      default() {
        return [$rules.isNumeric]
      }
    }
  },
  emits: ['update:modelValue', 'keyup', 'keydown', 'change', 'focus'],
  data() {
    //TODO -> fix unmasking of decimal values
    return { localType: 'text', internalValue: this.modelValue, hasFocus: false }
  },
  computed: {
    beautifulValue: {
      get() {
        return this.hasFocus ? this.internalValue : this.mask(this.internalValue)
      },
      set(value) {
        console.log('vnumberfield emit to parent value', value)
        this.internalValue = this.unmask(value)
        if (parseFloat(this.internalValue) === 'Nan') {
          if (this.internalValue === '') {
            this.$emit('update:modelValue', null)
          }
        } else {
          this.$emit('update:modelValue', Number(this.internalValue))
        }
      }
    }
  },
  methods: {
    isValid() {
      this.$refs.numberfield.validate()
      return this.$refs.numberfield.isValid === true
    },
    keyup(e: any): void {
      this.$emit('keyup', e)
    },
    keydown(e: any) {
      this.$emit('keydown', e)
    },
    change(e: any) {
      this.$emit('change', e)
    },
    focus(e: any) {
      this.hasFocus = true
      console.log('vNumberField got a focus ', e)
    },
    mask(value: Number | undefined | null) {
      if (value === null || value === undefined) return null

      return new Intl.NumberFormat('de-CH', {
        minimumFractionDigits: this.precision,
        maximumFractionDigits: this.precision
      })
        .format(value)
        .toString()
    },
    unmask(value: String) {
      if (value === '' || value === null || value == undefined) {
        return null
      }
      // remove thoused separators
      return value.toString().replace(/['’]/g, '')
    },
    isInt() {
      return this.precision == 0
    },
    isFloat() {
      return this.precision > 0
    },
    showDebug() {
      let settingsStore = useSettingsStore()
      return settingsStore.getDebug
    }
  },
  watch: {
    modelValue(to) {
      this.internalValue = Number(to)
    }
  }
})
</script>
<style scoped>
.align-right:deep(input) {
  text-align: right;
}
</style>
